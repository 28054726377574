import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import styled from 'styled-components'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'

// Components
import Image from 'components/shared/ImageGatsby'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonWhite from 'components/elements/ButtonWhite'

// SVG
import ArrowRightWhite from 'img/arrow-right-white.svg'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[21]};
    font-weight: ${(props) => props.theme.font.weight.s};
  }
`

const StyledImage = styled(Image)`
  @media (max-width: 767px) {
    max-height: 263px;
  }

  @media (min-width: 768px) {
    max-height: 357px;
  }

  @media (min-width: 1200px) {
    min-height: 488px;
    max-height: 488px;
  }
`

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected
      ? props.theme.color.face.secondary
      : props.theme.color.face.light} !important;
  font-weight: ${(props) => props.theme.font.weight.s};
  border: 1px solid ${(props) => props.theme.color.text.main};
  transition: background-color 0.2s ease-in-out;
  width: 100% !important;

  & > span {
    letter-spacing: 1.5px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.face.secondary};
    border: 1px solid ${(props) => props.theme.color.face.secondary};
  }
`

const CustomSvg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`

const ExcerptWrapper = styled.div`
  bottom: 100px;

  @media (max-width: 767px) {
    bottom: 0;
  }
`

const ExcerptContent = styled.div`
  min-height: 256px;
  background-color: ${(props) => props.theme.color.face.light};
  border: 1px solid ${(props) => props.theme.color.text.secondary};
`

const Excerpt = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[16]};
    color: ${(props) => props.theme.color.text.dark};
    line-height: ${(props) => props.theme.font.size[20]};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  min-width: 200px;
  max-width: 200px;

  & a {
    &:after {
      transition: all 0.2s ease-in-out;
      padding-left: 10px;
      content: url(${ArrowRightWhite});
    }
    &:hover:after {
      padding-left: 20px;
    }
  }

  @media (max-width: 991px) {
    max-width: 180px;
    min-width: 180px;
  }
`

const ProjectWrapper = styled.div`
  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.color.text.secondary};
  }
`

const ProjectButtonWrapper = styled.div`
  @media (min-width: 767px) {
    height: 100%;
  }

  @media (max-width: 1199px) {
    margin-left: -30px;
    margin-top: -30px;
    position: relative;
    top: -30px;
  }
`

const NoCategoryMessage = styled.h3`
  font-size: ${(props) => props.theme.font.size[21]};
  font-weight: ${(props) => props.theme.font.weight.s};
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => (
  <div className="container mt-lg-n5 mb-5">
    <div className="row justify-content-center">
      <div className="col-lg-9 col-12 d-flex flex-column">
        <Content content={fields.description} className="mb-5" />
        <Blogik
          postType="projects"
          settings={{
            id: 'blog',
          }}
        >
          <BlogFilters />
          {fields ? (
            <div>
              <BlogGrid projectFields={fields} />
            </div>
          ) : (
            <h4>Geen projecten gevonden, kijk later nog eens.</h4>
          )}
        </Blogik>
      </div>
    </div>
  </div>
)

interface BlogFilterNodeProps {
  node: {
    id: string
    name: string
    databaseId: number
    projectCategory: {
      icon: any
    }
  }
}

const BlogFilters: React.FC = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="row mb-5 pb-xl-5 mx-n1">
        {categories
          .sort(
            (a: BlogFilterNodeProps, b: BlogFilterNodeProps) =>
              a.node.databaseId - b.node.databaseId
          )
          .map((category: BlogFilterNodeProps) => (
            <div className="col-6 col-md-4 col-lg px-1 d-flex flex-grow-1">
              <StyledBlogFilter
                key={category.node.id}
                id={category.node.id}
                selected={isSelected(category.node.id)}
                className="d-flex py-2 px-3 mb-2 align-items-center justify-content-center"
              >
                <CustomSvg
                  src={category.node.projectCategory.icon.localFile.publicURL}
                  alt="Lofthus Icoon"
                  className="mr-2"
                />

                <span className="text-lowercase">{category.node.name}</span>
              </StyledBlogFilter>
            </div>
          ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  projectFields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const BlogGrid: React.FC<BlogGridProps> = ({ projectFields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton, hasPosts }: any) => {
      if (hasPosts) {
        return (
          <>
            <div>
              {posts.map((post: any, index: number) => (
                <Project
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fields={post}
                  projectFields={projectFields}
                />
              ))}
            </div>
            {showMoreButton && (
              <div className="mb-5 mt-5 mt-md-0 text-center">
                <ButtonWhite to="/" isCustom>
                  <BlogButton>laad meer projecten</BlogButton>
                </ButtonWhite>
              </div>
            )}
          </>
        )
      }
      return (
        <NoCategoryMessage className="text-center pb-5">
          Geen projecten gevonden in deze categorie
        </NoCategoryMessage>
      )
    }}
  </BlogConsumer>
)

interface ProjectProps {
  fields: any
  // eslint-disable-next-line
  projectFields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const Project: React.FC<ProjectProps> = ({ fields }) => (
  <BlogConsumer>
    {({ categories, hasPosts }: any) => {
      if (hasPosts) {
        const selectedCategories: Array<any> = []

        fields.node.projectCategories.nodes.forEach((projectCategory: any) => {
          const currentCategory: any = categories.find(
            (edge: any) => edge.node.id === projectCategory.id
          )

          if (currentCategory) {
            selectedCategories.push(currentCategory)
          }
        })

        return (
          <ProjectWrapper>
            <StyledImage
              image={fields.node.projectDetail.thumbnail}
              loading="lazy"
            />
            <div className="d-flex justify-content-center">
              <ExcerptWrapper className="row position-relative align-items-center justify-content-between mx-lg-5 mx-0">
                <ExcerptContent className="p-lg-5 p-4 col-md-9 col-12">
                  <div className="d-flex">
                    {selectedCategories.map((edge: any, index: number) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="pb-3 mr-4 d-flex align-items-center"
                      >
                        <img
                          src={
                            edge.node.projectCategory.icon.localFile.publicURL
                          }
                          className="mr-2"
                          alt="Lofthus icoon"
                        />
                        <span className="text-lowercase">{edge.node.name}</span>
                      </div>
                    ))}
                  </div>

                  <Excerpt content={fields.node.projectDetail.excerpt} />
                </ExcerptContent>
                <ProjectButtonWrapper className="d-flex justify-content-xl-center justify-content-end align-items-end px-0 align-items-xl-center col-md-3 col-12 mb-5 mb-md-0 mt-md-5 mt-0 pl-xl-5">
                  <StyledButtonDefault to={fields.node.uri} className="">
                    bekijk project
                  </StyledButtonDefault>
                </ProjectButtonWrapper>
              </ExcerptWrapper>
            </div>
          </ProjectWrapper>
        )
      }
      return <h4>Geen projecten gevonden...</h4>
    }}
  </BlogConsumer>
)

export default Projects
